<template>
    <div>
        <div class="box serach2">
            <div class="row">
                <div class="col-sm-8 mx-auto">
                    <div class="card ">
                        <div class="card-body px-0 py-0 ">
                        <form @submit.prevent="doSearch({request:request,page:1})" class=" d-flex justify-content-between">
                            <input class=" card-search-reversed color-normal-reversed" v-model="request" type="search" id="tec-search-input-s2" placeholder="ex: clients, n° facture, sites, methode de paiements, type de facture ..." name="search2" ref="search2">
                            <button  type="submit" class="btn btn-sm bg-primary mx-0 my-0"><i class="fas fa-search white-text" style="transform:scale(2);" ></i></button>
                        </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row results pt-5">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body card-reversed color-normal-reversed card-content-search" v-loading="loading" >
                         <el-checkbox-group v-model="checkedInv" @change="handleCheckedInvChange"> 

                        <mdb-tbl responsiveMd >
                            <mdb-tbl-head >
                                <tr>
                                    <th colspan="9" class="py-0" style="border-top:1px solid transparent">
                                        <div  class="d-flex justify-content-between align-items-center">
                                            <div class=" d-flex justify-content-start align-items-center">
                                                <el-checkbox :indeterminate="isIndeterminate" :value="isCheck" @change="handleCheckAllChange">Selectionner</el-checkbox>
                                                <el-tooltip class="item" effect="dark" content="Actualiser" placement="bottom">
                                                    <mdb-btn  tag="a" class="z-depth-0" color="" floating size="sm" @click="loadInvoiceBack(1)" ><mdb-icon  icon="redo-alt" color="primary" /></mdb-btn>
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" content="Supprimer" placement="bottom" v-show="this.checkedInv.length > 0" >
                                                    <mdb-btn  tag="a" class="z-depth-0 p-0" color="" floating size="sm" @click="deleteSelection()"><mdb-icon  icon="trash" color="primary" /></mdb-btn>
                                                </el-tooltip>
                                            </div>
                                            </div>
                                    </th>
                                </tr>
                            </mdb-tbl-head>
                            <mdb-tbl-head>
                                <tr class="color-normal-reversed">
                                    <th v-for="column in data.columns" :key="column.name" >
                                        {{column.label}}
                                    </th>
                                </tr>
                            </mdb-tbl-head>
                            <mdb-tbl-body class="color-normal-reversed">
                                <tr class="tec-table-row" v-for="row in queriedData" :key="row.invoice">
                                    <!-- <th class="tec-table-cell" scope="row">
                                        <img width="40" :src="$store.state.url+'storage/qrcodes/'+row.qrcode" alt="">
                                    </th> -->
                                    <td class="tec-table-cell" scope="row" >
                                         <el-checkbox :key="row.invoice" :label="row.invoice">{{ row.num_facture }}</el-checkbox>
                                    </td>
                                    <td class="tec-table-cell" >{{row.date_facture}}</td>
                                    <td class="tec-table-cell text-capitalize" >{{row.type}}</td>
                                    <td class="tec-table-cell" >
                                        <span v-if="row.customer.toLowerCase() !== 'divers'">{{row.customer}}</span>
                                        <span v-else-if="row.customer.toLowerCase() === 'divers' && row.specification">{{row.specification}}</span>
                                        <span v-else>{{row.customer}}</span>
                                    </td>
                                    <td class="tec-table-cell" >{{row.paiement_method}} - {{row.total | formatNumber}}</td>
                                    <td class="tec-table-cell"  style="text-transform:capitalize;"> {{row.seller_firstname}} <span style="text-transform:uppercase;">{{row.seller_lastname}}</span></td>
                                    <td class="tec-table-cell" >
                                        {{row.site}}
                                    </td>
                                    <td class="tec-table-cell" >
                                        <span class="badge badge-primary" v-if="row.cuid" > normalisée </span>
                                        <span class="badge badge-warning" v-if="!row.cuid" > en attente </span>
                                    </td>
                                    <td class="d-flex justify-content-end">
                                        <el-dropdown trigger="click">
                                            <span class="el-dropdown-link" style="cursor:pointer; font-weight:500" >
                                                Actions<i class="el-icon-arrow-down el-icon--right"></i>
                                            </span>
                                            <el-dropdown-menu class="dropdown-el-tec" slot="dropdown">
                                                <el-dropdown-item class="dropdown-item-el-tec" icon="el-icon-notebook-2" @click.native="details({num_facture:row.num_facture,id:row.invoice})">Détails</el-dropdown-item>
                                                <el-dropdown-item class="dropdown-item-el-tec" icon="el-icon-s-order" v-if="row.cuid" @click.native="exportPdf(row)">Exporter PDF</el-dropdown-item>
                                                <el-dropdown-item class="dropdown-item-el-tec" icon="el-icon-s-promotion" v-if="row.cuid" @click.native="sendInvoice(row)">Envoyer par mail</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </td>
                                </tr>
                            </mdb-tbl-body> 
                        </mdb-tbl>
                         </el-checkbox-group> 
                        <div v-if="empty"  class="d-flex justify-content-center w-100 p-5">
                            <img :src="require('../../../../assets/svg/empty_streec.svg')"  class="empty" alt="" srcset="">
                        </div>

                        <div class="d-flex justify-content-end">
                            <tec-pagination
                                v-model="pagination.pagination.currentPage"
                                :per-page="parseFloat(pagination.pagination.perPage)"
                                :total="pagination.pagination.total"
                                v-on:changePageEvent="loadInvoiceBack"
                            ></tec-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TecPagination from '@/tba/components/PaginationUrl'
import {
    mdbTbl,mdbTblHead,mdbTblBody,mdbBtn,mdbIcon
    
} from 'mdbvue'
export default {
    components:{
        mdbTbl,mdbTblHead,mdbTblBody,mdbIcon,
        TecPagination,mdbBtn
    },
     props:{
        empty:{
            type:Boolean,
            default:false
        },
        searchRequest:{
            type:String,
            default:""
        },
        loading:{
            type:Boolean,
            default:false
        },
        pagination:{
            type:Array,
            default: ()=>[]
        },
        data:{
            type:Array,
            default:() => []
        },
        pageData:{
            type: Array,
            default: () => []
        },
        queriedData:{
            type: Array,
            default: () => []
        },
        total:{
            type: Number,
            default: () => 0
        },
    },
    data(){
        return {
            request:this.searchRequest,

            selection:{
                select_all:false,
                selected:[]
            },

            checkAll: false,
            checkedInv: [],
            isIndeterminate: false
        }
    },

    methods:{
        handleCheckAllChange(val) {
            if (val) {
                for (const i in this.queriedData) {
                    this.checkedInv.push(this.queriedData[i].invoice)
                }
            }
            else {
                this.checkedInv = []
            }

            this.isIndeterminate = false;
        },

        checkAllDrop(mode) {
        this.checkedInv = []

        if (mode === 1) {
            for (const i in this.queriedData) {
            if (this.queriedData[i].cuid) {
                this.checkedInv.push(this.queriedData[i].invoice)
            } 
            }
        }
        else {
            for (const i in this.queriedData) {
            if (!this.queriedData[i].cuid) {
                this.checkedInv.push(this.queriedData[i].invoice)
            } 
            }
        }

        let checkedCount = this.checkedInv.length;
        this.checkAll = this.checkedCount === this.queriedData.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.queriedData.length;
        },

        handleCheckedInvChange(value) {
        let checkedCount = value.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.queriedData.length;
        this.checkAll = checkedCount === this.queriedData.length;
        },

        deleteSelection(){
            this.$emit("delete_facture_multiple", this.checkedInv);
        },

        doSearch (data){
            this.$emit('do_search',data)
        },
        loadInvoiceBack (e){
            this.$emit('load_invoice_back',e)
        },
        exportPdf (data){
            this.$emit('export_pdf',data)
        },
        sendInvoice (data){
            this.$emit('send_invoice',data)
        },
        details(data){
            this.$emit('details',data)
        },
    },

    computed: {
        
    }
}
</script>
<style lang="scss" scoped>
    input#tec-search-input-s2{
        transition: 200ms;
        border:none;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 0px 10px 1px rgba(49, 93, 238, 0.198);
        width: 100%;
        padding: 20px 10px 20px 20px;
        font-size: 1rem;
        color:rgb(28, 29, 31);
    }

    input#tec-search-input-s2:focus{
      outline: 0;
      background-color: #fff;
    }

    img.empty{
        width: 25%;
    }

    .search-icon{
      position:relative;
    }

    .search-icon i{
      color:rgb(49, 93, 238);
      position:absolute;
      left:5px; top:5px; padding:20px 5px;
    }
</style>