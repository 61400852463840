<template>
    <div>
        <div class="d-flex justify-content-start mb-3">
            <h3 class=" " style="font-weight:400" >Recherches</h3>
        </div>

        <div class="card card-search-os mb-4 px-0 py-0" style="border-radius:10px">
            <div class="card-body px-0 py-0">
                <form @submit.prevent="doSearch({request:request,page:1})">
                    <div class="tec-search-v-icon">
                        <input  style="background:transparent" v-model="request" type="search" ref="searchbar" name="search" placeholder="ex: clients, n° facture, sites, methode de paiements, type de facture ..." id="tec-search-v" class="tec-search-v">
                        <i class="fa fa-search fa-md fa-fw" aria-hidden="true"></i>
                    </div>
                </form>
            </div>
        </div>

        <div class="card card-list-os" v-if="empty" v-loading="loading" >
            <div class="card-body text-center">
                <div  class="d-flex justify-content-center w-100 p-5">
                    <img  class="w-50" :src="require('../../../../assets/svg/empty_streec.svg')"   alt="" srcset="">
                </div>
                    <h6>Aucune donnée</h6>
            </div>
        </div>
        
        <div class="" v-if="!empty" v-loading="loading">
            <div class="card card-list-os mb-3" style="border-radius:10px!important;box-shadow:0px 0px 4px 1px rgb(0,0,0,0.15) !important"  v-for="row in queriedData" :key="row.name">
                <div class="card-body py-1 pl-2 d-flex align-items-center justify-content-between" >
                    <div class="" style="max-width:90%">
                        <div class="mobile-card-header " style="font-size:0.8rem">
                            <span class="badger pb-2" style="font-size:0.8rem">
                                <span v-if="row.cuid" class="badge badge-success"> normalisée </span>
                                <span v-if="!row.cuid" class="badge badge-warning"> en attente </span>
                            </span>
                        </div>
                        <h5 class="card-title mobile-card-title pt-2 pb-2"  style="font-size:0.9rem;font-weight:700;font-family:'Nunito'"> 
                            <span style="text-transform:uppercase">N° {{row.num_facture}}</span>  
                            <span style="text-transform:uppercase" v-if="row.customer.toLowerCase() !== 'divers'">
                              | {{ row.customer}}
                            </span>
                            <span   style="text-transform:uppercase" v-else-if="row.customer.toLowerCase() === 'divers' && row.specification">
                                | {{row.specification}}
                            </span>
                            <span  style="text-transform:uppercase" v-else>
                            | {{row.customer}}
                            </span>
                        </h5>
                        
                        <div class="mobile-card-describe mt-n3 d-flex justify-content-start flex-wrap" style="font-weight:500;text-transform:uppercase;font-size:0.75rem; color:rgba(62, 69, 81, .7);">
                            <span class="item-mobile-spa">{{row.date_facture}}</span>
                            <span class="item-mobile-spa">{{row.type}}</span>
                            <span class="item-mobile-spa">{{row.paiement_method}} </span>
                            <span class="item-mobile-spa">{{ row.seller_firstname }}  <span style="text-transform:uppercase;"> {{ row.seller_lastname }}</span></span>
                        </div> 
                    </div>
                    <div class="action">
                        <button class="btn-mob" @click="showAction(row)" >
                            <i class="color-normal-reversed fas fa-ellipsis-v"></i>
                        </button>
                    </div>    
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <tec-pagination
                    v-model="pagination.pagination.currentPage"
                    :per-page="parseFloat(pagination.pagination.perPage)"
                    :total="pagination.pagination.total"
                    v-on:changePageEvent="loadInvoiceBack"
                ></tec-pagination>
            </div>
        </div>
        <mdb-modal  color="primary" centered size="sm" position="bottom" direction="bottom" :show="modalAction.show" @close="hideAction()">
            <mdb-modal-body class="px-0 py-0">
                <mdb-list-group flush>
                    <mdb-list-group-item  
                        class="bg-primary white-text font-weight-bold" > 
                        <div>MENU</div> 
                        <button class="btn-mob" @click="hideAction()"> 
                        <i class="fas fa-times"></i> 
                        </button> 
                    </mdb-list-group-item>
                    <mdb-list-group-item  
                        class="menu-tec-lg"
                        @click.native="details({id:modalAction.content.invoice})"
                        icon="align-center"  
                        style="font-weight:400;">
                        <div class="pl-3 menu-mob-tec">Voir les détails</div>
                    </mdb-list-group-item>
                    <mdb-list-group-item  class="menu-tec-lg"
                        @click.native="exportPdf(modalAction.content)" :disabled="!modalAction.content.cuid"
                        :class="(modalAction.content.cuid) ?'black-text' :'grey-text'" 
                        icon="file" 
                         style="font-weight:400;" >
                        <div class="pl-3 menu-mob-tec">Exporter en Pdf</div>
                    </mdb-list-group-item>
                    <mdb-list-group-item  class="menu-tec-lg"
                        @click.native="sendInvoice(modalAction.content)" :disabled="!modalAction.content.cuid"
                        :class="(modalAction.content.cuid) ?'black-text' :'grey-text'" 
                        icon="paper-plane" 
                         style="font-weight:400;" >
                        <div class="pl-3 menu-mob-tec">Envoyer par mail</div>
                    </mdb-list-group-item>
                </mdb-list-group>
            </mdb-modal-body>
        </mdb-modal>
    </div>
</template>
<script>
import TecPagination from '@/tba/components/PaginationUrl'

import {
    mdbModal,  mdbModalBody,
    mdbListGroup, mdbListGroupItem ,
} from 'mdbvue'
export default {
    components:{
        mdbModal,  mdbModalBody,
        mdbListGroup, mdbListGroupItem ,
        TecPagination
    },
    props:{
        empty:{
            type:Boolean,
            default:false
        },
        searchRequest:{
            type:String,
            default:""
        },
        loading:{
            type:Boolean,
            default:false
        },
        pagination:{
            type:Array,
            default: ()=>[]
        },
        data:{
            type:Array,
            default:() => []
        },
        pageData:{
            type: Array,
            default: () => []
        },
        queriedData:{
            type: Array,
            default: () => []
        },
        total:{
            type: Number,
            default: () => 0
        },
    },
    data(){
        return {
            request:this.searchRequest,
            modalAction:{
                show:false,
                content:{}
            }
        };
    },
    methods:{
        showAction(data){
            this.modalAction.content  = data
            this.modalAction.show = true
        },
        hideAction(){
            this.modalAction.content  = {}
            this.modalAction.show = false
        },

        doSearch (data){
            this.modalAction.show = false
            this.$emit('do_search',data)
        },
        loadInvoiceBack (e){
            this.modalAction.show = false
            this.$emit('load_invoice_back',e)
        },
        exportPdf (data){
            this.modalAction.show = false
            this.$emit('export_pdf',data)
        },
        sendInvoice (data){
            this.modalAction.show = false
            this.$emit('send_invoice',data)
        },
        details(data){
            this.modalAction.show = false
            this.$emit('details',data)
        },
    },
       
}
</script>

<style lang="scss">
  
</style>