<template>
    <div class="container-fluid">
        <list-search-desktop
            v-if="!$isMobile()"
            :empty="empty"
            :loading="contentSearch"
            :pagination="dataPagination"
            :queriedData="queriedData"
            :data="data"
            :total="total"
            :searchRequest="request"
            v-on:do_search="doSearch"
            v-on:load_invoice_back="loadInvoiceBack"
            v-on:export_pdf="exportPdf"
            v-on:details="details"
            v-on:send_invoice="sendInvoice"
            v-on:delete_facture_multiple="deleteFactureMultiple"
        >
        </list-search-desktop>

        <list-search-mobile
            v-if="$isMobile()"
            :empty="empty"
            :loading="contentSearch"
            :pagination="dataPagination"
            :queriedData="queriedData"
            :data="data"
            :total="total"
            :searchRequest="request"
            v-on:do_search="doSearch"
            v-on:load_invoice_back="loadInvoiceBack"
            v-on:export_pdf="exportPdf"
            v-on:details="details"
            v-on:send_invoice="sendInvoice"
        >
        </list-search-mobile>

        <!-- <mdb-modal size="md" centered style="z-index:1892"  :show="modalSender.show" @close="modalSender.show = false">
            <mdb-modal-body class="modal-tec card-reversed">
                <div class="modal-times-tec "  @click="modalSender.show = false"> <i class="fas fa-times"></i> </div>
                <mdb-modal-title class="text-primary white-text" style="font-weight:500 !important; font-size:1.5rem !important;">
                {{ modalSender.title }}</mdb-modal-title>

                <br>
                <p class="color-normal-reversed"> Aucune adresse e-mail n'a été enregistrée au nom du client <b>{{modalSender.content.customer_name}}</b>.  <br>
                Veuillez renseigner l'email de destination.
                </p>

                <form @submit.prevent="sendByMail">
                    <div class="row">
                        <div class="col-sm-12">
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                             type="email" outline size="lg" label="Adresse Email" v-model="modalSender.content.customer_email" placeholder="dupont@gmail.co"></mdb-input>
                        </div>
                    </div>

                <div class="mt-3 d-flex justify-content-around">
                    <button class="btn btn-md btn-primary" type="submit" :disabled="modalSender.btn_sender">
                        <span v-if="!modalSender.btn_sender"><i class="fas fa-paper-plane"></i> Envoyer</span>
                        <span v-if="modalSender.btn_sender" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span v-if="modalSender.btn_sender" class="pl-2">Loading...</span>
                    </button>
                    <button type="button" class="btn btn-md btn-outline-primary" @click="hideModalSender">
                        Annuler
                    </button>
                </div>
                </form>

            </mdb-modal-body>
        </mdb-modal> -->

        <mdb-modal size="md" centered  :show="modalSender.show" @close="modalSender.show = false">
            <form @submit.prevent="sendByMail">
            <mdb-modal-header>
                <mdb-modal-title>
                {{ modalSender.title }}</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body > 
                <p> Aucune adresse e-mail n'a été enregistrée au nom du client <b>{{modalSender.content.customer_name}}</b>.  <br>
                Veuillez renseigner l'email de destination.
                </p>
                
                <div class="row">
                    <div class="col-sm-12">
                        <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                            type="email" outline size="lg" label="Adresse Email" v-model="modalSender.content.customer_email" placeholder="dupont@gmail.co"></mdb-input>
                    </div>
                </div> 

            </mdb-modal-body>
            <mdb-modal-footer>
                    <button class="btn btn-md btn-primary" type="submit" :disabled="modalSender.btn_sender">
                    <span v-if="!modalSender.btn_sender"><i class="fas fa-paper-plane"></i> Envoyer</span>
                    <span v-if="modalSender.btn_sender" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-if="modalSender.btn_sender" class="pl-2">Loading...</span>
                </button>
                <button type="button" class="btn btn-md btn-outline-primary" @click="hideModalSender">
                    Annuler
                </button>
            </mdb-modal-footer>
            </form>
        </mdb-modal>
    </div>
</template>

<script>
import download from "../../../services/download-fetch"        
import ListSearchDesktop from '@/tba/views/invoices/modules/ListSearchDesktop'
import ListSearchMobile from '@/tba/views/invoices/modules/ListSearchMobile'
import {
mdbModal,
mdbModalTitle,
mdbModalBody,
mdbModalHeader,
mdbModalFooter,
mdbInput,
} from 'mdbvue' 
export default {
    name:"search",
    components:{
        ListSearchDesktop,
        ListSearchMobile,
        mdbModal,
        mdbModalTitle,
        mdbModalBody,
        mdbModalHeader,
        mdbModalFooter,
        mdbInput,
    },
    data(){
        return{
            contentSearch:false,
            empty:true,
            dataPagination: {
                pagination: {
                    perPage: 5,
                    currentPage: 1,
                    perPageOptions: [
                        {text:"5",value:"5", selected: true},
                        {text:"10",value:"10"},
                        {text:"25",value:"25"},
                        {text:"50",value:"50"},
                        {text:"100",value:"100"},
                    ],
                    total: 0,
                },
                searchQuery: "",
                propsToSearch: [
                    "type",
                    "num_facture",
                    "date_facture",
                    "customers",
                    "depots",
                    "accounting_categories",
                    "users",
                    "payments",
                    // "is_active",
                    // "uid"
                ],
            },
            data:{
                columns: [
                    {
                        label: "facture#",
                        field: "num_facture",
                        sort: true,
                        role: true
                    },
                    
                    {
                        label: "Date",
                        field: "date_facture",
                        sort: true,
                        role: true
                    },
                    {
                        label: "Type",
                        field: "type",
                        sort: true,
                        role: true
                    },
                    {
                        label: "Client",
                        field: "customer",
                        sort: true,
                        role: true
                    },
                    {
                        label: "Paiement",
                        field: "payment_method",
                        sort: true,
                        role: true
                    },
                    {
                        label: "Vendeur",
                        field: "seller",
                        sort: true,
                        role: true
                    },
                    {
                        label: "Site",
                        field: "site",
                        sort: true,
                        role: true
                    },
                    {
                        label: "Etat",
                        field: "cuid",
                        sort: true,
                        role: true
                    },
                    
                    {
                        label: "",
                        field: "action",
                        sort: true,
                        role: true
                    },
                ],
                invoices:{
                    current_page:1,
                    data:[]
                },
            },
            modalSender:{
                show:false,
                title:"Envoyer une facture",
                content:{
                    customer_email:"",
                    customer_name:"",
                    invoice:"",
                    invoiceuid:""
                },
                btn_sender:false
            },
            request:this.$router.history.current.query.q
        }
    },
    methods:{ 
        async doSearch({request,page}){
            if(this.$router.currentRoute.fullPath !== `/factures/recherches?q=${request}&page=${page}` )
            {
                this.$router.replace({ path: "/factures/recherches", query: {q: request} })

            }
            if(request.trim() === undefined || request ==="") {
                return
            }
            this.request = request
            this.contentSearch = !this.contentSearch
            this.$nprogress.start()
            await this.$store.dispatch('invoice/search', {q:request,page:page}).then((response) => {
                this.contentSearch = !this.contentSearch
                this.$nprogress.done()

                if(response.data.results.data.length > 0 ){
                    this.empty = false
                }else{
                    this.empty = true
                }
                this.data.invoices = response.data.results
                this.dataPagination.pagination.currentPage = response.data.results.current_page
                this.dataPagination.pagination.perPage = response.data.results.per_page
                this.dataPagination.pagination.total = response.data.results.total
            }).catch((error) => {
                this.contentSearch = !this.contentSearch
                this.$nprogress.done()
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },
        loadInvoiceBack(e){
            this.doSearch({
                request:this.request,
                page:e
            })
        },

        async exportPdf(row){
            let th = 0
            let doyouPrint = await this.$store.dispatch('swal/doYouPrintPdf')

            if(doyouPrint.isConfirmed){
                th = 0
            }else if (doyouPrint.isDenied){
                th = 1   
            }
            else{
                return 
            }

            this.$nprogress.start()
            await download(
                {
                url:'/print/pdf/'+row.invoice+'?th='+th,
                isTh:th,
                filename:row.num_facture+'.pdf'
                }
            ).then(() => {
                this.$nprogress.done();
                this.$notify({
                    message: "PDF Généré avec succès",
                    type: 'success'
                })
            })
            .catch(() => {
                this.$nprogress.done();
                this.$notify({
                       
                    message: "Echec durant l'exportation, reessayer ulterieurement",
                    type: 'danger'
                })
            })
        },

        details({id}) {
            this.$router.push({
                path:"/factures/details/"+id,
            }) 
        },

        async sendInvoice(row){
            if(!row.cuid){
                this.$notify({
                       
                    message: "La facture n'est pas encore normalisée " + row.uid,
                    type: 'danger'
                })
                return
            }

            this.modalSender.content.customer_name = (row.customer.toLowerCase() !== 'divers' && !row.specification) ? row.customer:row.specification;
            this.modalSender.content.customer_email = row.customer_email;
            this.modalSender.content.invoiceuid = row.cuid;
            this.modalSender.content.invoice = row.invoice;

            if (!row.customer_email) {
                this.modalSender.show = true
                return 
            }

            await this.sendByMail().then(() => {
                this.hideModalSender()
            })
        },

        async deleteFactureMultiple(factures){
            let res = await this.$store.dispatch("swal/doYouDelete");
            if (res.isDismissed) {
                return;
            }
            this.$nprogress.start();
            await this.$store
            .dispatch("invoice/deleteMulti", {factures:factures})
            .then((response) => {
            this.$nprogress.done();
            this.$notify({
                    
                message: response.data.message,
                type: "success",
            });
            this.loadInvoiceBack({
                request:this.request,
                page:1
            });
            })
            .catch((error) => {
            this.$nprogress.done();
            this.$notify({
                message: error.response.data.message || error.response.data.error,
                type: "danger",
            });
            });
        },

        async sendByMail(){
            if(!this.modalSender.content.invoiceuid){
                this.$notify({
                       
                    message: "La facture n'est pas encore normalisée ",
                    type: 'danger'
                })
                return
            }
            this.modalSender.btn_sender = !this.modalSender.btn_sender 
            this.$nprogress.start()
			await this.$store.dispatch('invoice/sendInvoice',this.modalSender.content)
                .then((response) => {
                    this.modalSender.btn_sender = !this.modalSender.btn_sender
                    this.$nprogress.done()
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    }) 
                    this.hideModalSender()
                    
                })
                .catch(error =>{
                    this.modalSender.btn_sender = !this.modalSender.btn_sender
                    this.$nprogress.done()
                    this.$notify({
                           
                        message: error.response.data.message  || error.response.data.error,
                        type: 'danger'
                    })
                })
        },

        hideModalSender(){
            this.modalSender.content.customer_email = ""
            this.modalSender.content.customer_name = ""
            this.modalSender.content.invoice = ""
            this.modalSender.content.invoiceuid = ""
            this.modalSender.show = false
        },

         // Systeme de datatable
        totalCalcul () {
            return this.dataPagination.pagination.total = this.data.invoices.total;
        },

        searchQ () {
            if (!this.dataPagination.searchQuery) {
                // return this.pagedData;
                return new Error('Nothing To Search')
            }
            
            let result = this.data.invoices.data.filter((row) => {
                let isIncluded = false;
                for (let key of this.dataPagination.propsToSearch) {
                    let rowValue = "";
                    if (typeof row[key] === "object") {
                        switch (key) {
                            case "depots":  
                                rowValue = (row[key]) ? (row[key].designation).toString().toLowerCase() : '';
                                break;
                            
                            case "customers":  
                                rowValue = (row[key]) ? (row[key].designation).toString().toLowerCase() : '';
                                break;

                            case "accounting_categories":  
                                rowValue = (row[key]) ? (row[key].category).toString().toLowerCase() : '';
                                break;
                            
                            case "payments":  
                                rowValue = (row[key]) ? (row[key].moyens).toString().toLowerCase() : '';
                                break;
                            case "users":  
                                rowValue = (row[key]) ? (row[key].firstname).toString().toLowerCase()+ ' '+(row[key].lastname).toString().toLowerCase() : '';
                                break;
                            default:
                                break;
                        }
                    }

                    if (typeof row[key] === 'string') {
                        switch(key){
                            case "uid":
                                rowValue = (row[key]) ?  "normalisée": "en attente" ;
                                break;
                            default:
                                rowValue = row[key].toString().toLowerCase();

                                break;
                        }
                    }
                    if (
                        rowValue.includes &&
                        rowValue.includes(this.dataPagination.searchQuery.toLowerCase())
                        ) { 
                        isIncluded = true;
                        }   
                }
                return isIncluded;
            });
            this.dataPagination.pagination.total = result.length;
            return result.slice(this.from, this.to);
        },

        nbrPerPageSelected(value){
            let nbr = value;
            return this.nbrPerPage(nbr)
        },

        nbrPerPage (nbr) {
            this.doSearch({
                page:1
            })
           return this.dataPagination.pagination.perPage = nbr
        },
    },
    mounted(){
    },      
    created(){
        setTimeout(() => {
            this.request =  this.$router.history.current.query.q
            if(this.request){
                this.doSearch({
                    request:this.request,
                    page:1
                }) 
            }
        }, 1000)
        
    },
    computed: {
        pageData () {
            return this.data.invoices.data;
        },

        queriedData() {
            let result = this.searchQ();
            return (result instanceof Error) ? this.pageData : result;
        },

        total() { 
            return this.totalCalcul();
        },
    }
}
</script>

